import moment, { Moment } from "moment";
import { paid, pending, late, unpaid } from "../constants/paiementStatus";
import { PaiementStatus } from "../stateMachine/rentStatus/rentStatus.type";

export const getPaiementStatus = (
  date: Moment,
  isPayed: boolean
): PaiementStatus => {
  if (isPayed) return paid;

  if (moment(Date.now()).diff(date, "days") < 1) return pending;

  if (moment(Date.now()).diff(date, "days") < 30) return late;

  return unpaid;
};
