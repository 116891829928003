import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { fetchTenants } from "../../api/tenant";
import { Tenant } from "../../models/Tenant";
import AuthStore from "../auth/AuthStore";

export default class TenantStore {
  public auth: AuthStore;

  @observable
  tenants: Tenant[] | [] = [];

  constructor(rootStore: any) {
    this.auth = rootStore.authStore;

    makeAutoObservable(this);
  }

  @action
  async fetchTenants() {
    try {
      const tenants: any = await fetchTenants(this.auth.userToken);

      runInAction(() => {
        this.tenants = tenants["hydra:member"];
      });
    } catch (error) {
      console.log("fetchTenants", error);
    }
  }
}
