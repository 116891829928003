import axios from "axios";
import { Accomodation, AccomodationDTO } from "../models/Accomodation";
import { Type } from "../models/Type";

export const fetchAccomodations = async (
  token: string | undefined,
  ulid: string | undefined
): Promise<Accomodation[]> => {
  return new Promise((resolve, reject) => {
    if (token === undefined || token === "")
      reject(new Error("token not provided"));

    if (ulid === undefined || ulid === "")
      reject(new Error("ulid not provided"));

    const baseURL = process.env.REACT_APP_API_URL;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios({
      headers,
      method: "GET",
      baseURL,
      url: `/users/${ulid}/accomodations`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchAccomodationById = async (
  token: string | undefined,
  id: number | undefined
): Promise<Accomodation> => {
  return new Promise((resolve, reject) => {
    if (token === undefined || token === "")
      reject(new Error("token not provided"));

    if (!id || isNaN(id)) reject(new Error("id not provided"));

    const baseURL = process.env.REACT_APP_API_URL;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios({
      headers,
      method: "GET",
      baseURL,
      url: `/accomodations/${id}`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchAccomodationsType = async (
  token: string | undefined
): Promise<Type[]> => {
  return new Promise((resolve, reject) => {
    if (token === undefined || token === "")
      reject(new Error("token not provided"));

    const baseURL = process.env.REACT_APP_API_URL;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios({
      headers,
      method: "GET",
      baseURL,
      url: `/accomodation_types`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createAccomodation = async (
  token: string | undefined,
  accomodation: AccomodationDTO | undefined
): Promise<Accomodation> => {
  return new Promise((resolve, reject) => {
    if (token === undefined || token === "")
      reject(new Error("token not provided"));
    if (accomodation === undefined)
      reject(new Error("accomodation not provided"));

    const baseURL = process.env.REACT_APP_API_URL;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios({
      headers,
      method: "POST",
      baseURL,
      url: `/accomodations`,
      data: accomodation,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
