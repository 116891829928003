import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { AccomodationSelectorComponent } from "./AccomodationSelector.type";
import { getPaiementStatus } from "../../../lib/utils/getPaiementStatus";
import { late, paid, unpaid } from "../../../lib/constants/paiementStatus";

export const AccomodationSelector: FC<AccomodationSelectorComponent> = ({
  accomodation,
  currentId,
}) => {
  const [paimentStatus, setPaiementStatus] = useState(paid);

  useEffect(() => {
    setPaiementStatus(paid);
    accomodation.rents.forEach((rent) => {
      const pStatus = getPaiementStatus(moment(rent.initialDate), rent.payed);

      if (pStatus === unpaid || pStatus === late) {
        if (pStatus === late && paimentStatus !== unpaid) {
          setPaiementStatus(late);
        } else {
          setPaiementStatus(unpaid);
        }
      }
    });
  }, [currentId, accomodation]);

  return (
    <Link
      to={`${accomodation.id}`}
      style={{ textDecoration: "none", color: "black" }}
    >
      <div
        className={`accomodationsListItemSubItem${
          accomodation.id === currentId
            ? ` accomodationsListItemSubItem--current`
            : ""
        }`}
      >
        <p
          className={`paymentStatus paymentStatus--${
            !accomodation.status || paimentStatus.slug
          }`}
        >
          <span className="sr-only">{paimentStatus.label}</span>
        </p>
        <div className="accomodationsListItemSubItemDetails">
          <h4>
            {accomodation.name}
            <span> - {accomodation.rentPrice}€</span>
          </h4>
          <p>
            {accomodation.status
              ? `M ${accomodation.tenant.lastName}`
              : "Sans locataire"}
          </p>
        </div>
        <p className="accomodationsListItemSubItemStatus">
          <span
            className={`status${
              accomodation.status ? " status--occupied" : ""
            }`}
          >
            {accomodation.status ? "Occupé" : "Vide"}
          </span>
          <span className="surface">{accomodation.surface} m2</span>
        </p>
      </div>
    </Link>
  );
};
