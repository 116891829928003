import React from "react";
import i18n from "i18n-js";
import { useModalStore } from "../../../lib/store/modal/ModalStore";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx";

export const ConsentModal = observer(() => {
  const modal = useModalStore();

  const accept = () => {
    runInAction(() => {
      modal.visible = false;
    });

    modal.handleAccept!();
  };

  const denied = () => {
    runInAction(() => {
      modal.visible = false;
    });

    if (modal.handleDenied) modal.handleAccept!();
  };

  return (
    <>
      {modal.visible && (
        <div className="overlay">
          <div className="overlayContent">
            <h2>{modal.title}</h2>
            <p>{modal.description}</p>
            <p className="buttons">
              <button onClick={accept}>{i18n.t("yes")}</button>
              <button onClick={denied}>{i18n.t("no")}</button>
            </p>
          </div>
        </div>
      )}
    </>
  );
});
