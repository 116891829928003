import React, { useState, FC, useEffect } from "react";
import i18n from "i18n-js";
import { useParams } from "react-router-dom";
import { AccomodationSelector } from "../Accomodation/AccomodationSelector/AccomodationSelector";
import { AccordianComponent } from "./Accordian.type";

export const Accordian: FC<AccordianComponent> = ({ group }) => {
  const [visible, setVisible] = useState(false);
  const [currentTenants, setCurrentTenants] = useState<number>(0);
  const { accomodationId } = useParams();

  const handleClick = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    let count: number = 0;
    group.accomodations.forEach((acc) => {
      if (acc.status) count++;
    });

    setCurrentTenants(count);
  }, [group]);

  useEffect(() => {
    group.accomodations.forEach((acc) => {
      if (acc.id === Number(accomodationId)) {
        setVisible(true);
      }
    });
  }, [accomodationId]);

  return (
    <div className="accomodationsListItem">
      <div className="accomodationsListItemMain" onClick={handleClick}>
        <p
          className={`accomodationsListItemMainToggle${
            visible ? " opened" : ""
          }`}
        >
          <i className="fas fa-chevron-down" />
          <span className="sr-only">Afficher les appartements</span>
        </p>
        <p className="accomodationsListItemMainThumbnail">
          <img
            src={
              group.picture
                ? group.picture
                : "https://picsum.photos/id/299/300/200"
            }
            alt=""
          />
        </p>
        <div className="accomodationsListItemMainContent">
          <h3 className="accomodationsListItemMainContentTitle">
            {group.name}
          </h3>
          <p className="accomodationsListItemMainContentLocation">
            <i className="fa-solid fa-location-dot" />
            {group.city}
          </p>
          <ul className="accomodationsListItemMainContentDetails">
            <li>
              <i className="fa-solid fa-house-chimney" />
              {group.accomodations.length} {i18n.t("housing")}
            </li>
            <li>
              <i className="fa-solid fa-user" />
              {currentTenants} {i18n.t("tenants")}
            </li>
          </ul>
        </div>
      </div>
      {visible && (
        <div className="accomodationsListItemSub">
          {group.accomodations.map((accomodation) => (
            <AccomodationSelector
              key={accomodation.id}
              accomodation={accomodation}
              currentId={Number(accomodationId)}
            />
          ))}
        </div>
      )}
    </div>
  );
};
