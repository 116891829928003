export const fileConvertSize = (size: number) => {
  size = Math.abs(size);

  const def: Array<[number, string]> = [
    [1, "Octets"],
    [1000, "KO"],
    [1000 * 1000, "MO"],
    [1000 * 1000 * 1000, "GO"],
    [1000 * 1000 * 1000 * 1000, "TO"],
  ];

  for (let i = 0; i < def.length; i++) {
    if (size < def[i][0])
      return (size / def[i - 1][0]).toFixed(2) + " " + def[i - 1][1];
  }
};

export const splitNameExtention = (file: File) => {
  const index = file.name.lastIndexOf(".");
  const name = file.name.slice(0, index);
  const extention = file.name.slice(index + 1);

  return { name, extention };
};
