import AccomodationStore from "./accomodation/AccomodationStore";
import AuthStore from "./auth/AuthStore";
import CreateAccomodationStore from "./createAccomodation/CreateAccomodationStore";
import CreateGroupStore from "./createGroup/CreateGroupStore";
import GroupStore from "./group/GroupStore";
import LoaderStore from "./loader/LoaderStore";
import ModalStore from "./modal/useModalStore";
import RentStore from "./rent/RentStore";
import TagStore from "./tag/TagStore";
import TenantStore from "./tenant/TenantStore";
import UserStore from "./user/UserStore";

export default class RootStore {
  loaderStore: LoaderStore;

  authStore: AuthStore;

  userStore: UserStore;

  groupStore: GroupStore;

  createGroupStore: CreateGroupStore;

  accomodationStore: AccomodationStore;

  createAccomodationStore: CreateAccomodationStore;

  tagStore: TagStore;

  tenantStore: TenantStore;

  rentStore: RentStore;

  modalStore: ModalStore;

  constructor() {
    this.loaderStore = new LoaderStore();
    this.modalStore = new ModalStore();
    this.groupStore = new GroupStore(this);
    this.userStore = new UserStore(this);
    this.authStore = new AuthStore(this);
    this.accomodationStore = new AccomodationStore(this);
    this.createGroupStore = new CreateGroupStore(this);
    this.createAccomodationStore = new CreateAccomodationStore(this);
    this.tagStore = new TagStore(this);
    this.tenantStore = new TenantStore(this);
    this.rentStore = new RentStore(this);
  }
}
