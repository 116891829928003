import { action, computed, makeAutoObservable, observable } from "mobx";

export default class LoaderStore {
  @observable
  count: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  startLoading() {
    this.count += 1;
  }

  @action
  stopLoading() {
    if (this.count > 0) this.count -= 1;
    if (this.count === 0 || this.count < 0) this.count = 0;
  }

  @computed
  get loading() {
    return this.count > 0;
  }
}
