export const pending = {
  label: "En attente",
  slug: "",
};

export const paid = {
  label: "Payé",
  slug: "paid",
};

export const late = {
  label: "En retard",
  slug: "late",
};

export const unpaid = {
  label: "Impayé",
  slug: "unpaid",
};
