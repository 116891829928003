import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { fetchGroupForUser } from "../../api/groups";
import { Group } from "../../models/Group";
import { getAccomodationWithoutGroup } from "../../utils/getAccomodationWithoutGroup";
import AccomodationStore from "../accomodation/AccomodationStore";
import LoaderStore from "../loader/LoaderStore";

export default class GroupStore {
  public loader: LoaderStore;

  public accomodation: AccomodationStore;

  @observable
  groups: Group[] | [] = [];

  constructor(rootStore: any) {
    this.loader = rootStore.loaderStore;
    this.accomodation = rootStore.accomodationStore;

    makeAutoObservable(this);

    makePersistable(this, {
      name: "GroupStore",
      properties: ["groups"],
      storage: window.localStorage,
    });
  }

  @action
  async setGroups(groups: Group[]) {
    runInAction(() => {
      this.groups = groups;
    });
  }

  @action
  async fetchGroupForUser(token: string, ulid: string) {
    try {
      const grps = await fetchGroupForUser(token, ulid);
      const accWithoutGroups = await getAccomodationWithoutGroup(token, ulid);

      if (accWithoutGroups.length > 0) {
        grps.push({
          id: 0,
          name: "Non groupé",
          city: "N/A",
          description: "Logement n'ayant pas de groupe",
          accomodations: accWithoutGroups,
          picture: 'https://picsum.photos/300/200'
        });
      }
      this.setGroups(grps);
    } catch (error) {
      console.log("fetchGroupForUser", error);
    }
  }
}
