import React, { useEffect } from "react";
import i18n from "i18n-js";
import { useParams } from "react-router-dom";
import { useMachine } from "@xstate/react";
import { observer } from "mobx-react-lite";
import { AccomodationDocumentsList } from "../components/Accomodation/AccomodationDocuments/List/AccomodationDocumentsList";
import { AccomodationInfo } from "../components/Accomodation/AccomodationInfos/AccomodationInfos";
import { accomodationPageMachine } from "../lib/stateMachine/accomodationPage/accomodationPage";
import { AccomodationHistoryList } from "../components/Accomodation/AccomodationHistory/List/AccomodationHistoryList";
import { AccomodationRentsList } from "../components/Accomodation/AccomodationRents/List/AccomodationRentsList";
import { TenantsInfos } from "../components/Tenants/TenantsInfos/TenantsInfos";
import { useAccomodationStore } from "../lib/store/accomodation/useAccomodationStore";

export const AccomodationScreen = observer(() => {
  const { accomodationId } = useParams();
  const accomodation = useAccomodationStore();

  const [current, send] = useMachine(accomodationPageMachine);
  const { currentPage } = current.context;

  useEffect(() => {
    (async () => {
      await accomodation.fetchAccomodationById(Number(accomodationId));
    })();
  }, [accomodationId]);

  return (
    accomodation.current && (
      <>
        <div className="mainContentHeader">
          <div className="loopAccomodation">
            <p className="loopAccomodationThumbnail">
              <img
                src={
                  accomodation.current.picture
                    ? accomodation.current.picture
                    : "https://picsum.photos/id/424/1920/1080"
                }
                alt=""
              />
            </p>

            <div className="loopAccomodationContent">
              <h1>
                {accomodation.current?.type.label.toUpperCase()} - {""}
                {accomodation.current &&
                  i18n.t("current_floor", {
                    count: accomodation.current?.floor,
                  })}
              </h1>
              <p className="loopAccomodationContentLocation">
                <i className="fa-solid fa-location-dot"></i>{" "}
                {accomodation.current?.address}
              </p>

              <p>
                <a
                  href={`https://www.google.fr/maps/place/${accomodation.current?.address}`}
                  target="_blank"
                  className="button"
                >
                  {i18n.t("open_maps")}
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="mainContentBody mainContentBody--board">
          <>
            <div className="rentContent">
              <ul className="tags tags--panel">
                <li
                  className={currentPage === "infos" ? "current" : ""}
                  onClick={() => send("INFOS")}
                >
                  {i18n.t("basic_informations")}
                </li>
                <li
                  className={currentPage === "docs" ? "current" : ""}
                  onClick={() => send("DOCUMENTS")}
                >
                  {i18n.t("documents")}
                </li>
                <li
                  className={currentPage === "history" ? "current" : ""}
                  onClick={() => send("HISTORY")}
                >
                  {i18n.t("history")}
                </li>
              </ul>

              {currentPage === "infos" ? (
                <AccomodationInfo accomodation={accomodation.current} />
              ) : currentPage === "docs" ? (
                <AccomodationDocumentsList
                  documents={accomodation.current.documents}
                />
              ) : (
                <AccomodationHistoryList
                  histories={accomodation.current.histories}
                />
              )}
            </div>

            <div className="rentSidebar">
              <div className="tenantInfos">
                <TenantsInfos accomodation={accomodation.current} />
              </div>
              <div className="tenantRents">
                <AccomodationRentsList accomodation={accomodation.current} />
              </div>
            </div>
          </>
        </div>
      </>
    )
  );
});
