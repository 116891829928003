import { EnergyClass } from "../models/Accomodation";
import { Month } from "../models/Rent";

export const energyClass: Array<keyof typeof EnergyClass> = Object.keys(
  EnergyClass
) as Array<keyof typeof EnergyClass>;

export const months: Array<keyof typeof Month> = Object.keys(Month) as Array<
  keyof typeof Month
>;
