import React from "react";
import Select from "react-select";
import { useMachine } from "@xstate/react";
import moment from "moment";
import { rentStatusMachine } from "../lib/stateMachine/rentStatus/rentStatus";
import { useLoaderStore } from "../lib/store/loader/useLoaderStore";

export const accomodations = [
  { value: "appartement", label: "Appartement" },
  { value: "garage", label: "Garage" },
  { value: "immeuble", label: "Immeuble" },
  { value: "maison", label: "Maison" },
  { value: "ferme", label: "Ferme" },
];

export const DashboardScreen = () => {
  const date = moment("10/01/2022", "DD/MM/YYYY");
  const [current, send] = useMachine(rentStatusMachine, {
    actions: {
      load: () => send("INIT", { value: { date, isPayed: false } }),
    },
  });
  const { label, slug } = current.context.paiementStatus;
  const loader = useLoaderStore();

  const handleLoader = () => {
    loader.startLoading();

    setTimeout(() => {
      loader.stopLoading();
    }, 2000);
  };

  return (
    <div className="accomodations">
      <p>Sandbox screen</p>
      <Select
        className="basic-single"
        classNamePrefix="select"
        isClearable
        isSearchable
        name="color"
        options={accomodations}
        components={{
          DropdownIndicator: null,
        }}
      />
      <Select
        isMulti
        name="colors"
        options={accomodations}
        className="basic-multi-select"
        classNamePrefix="select"
      />
      <button onClick={handleLoader}>test loader</button>
      <button onClick={() => send("TOGGLE", { value: { date } })}>
        Click me
      </button>{" "}
      <code>
        {label} / {slug}
      </code>
    </div>
  );
};
