import {
  action,
  observable,
  makeAutoObservable,
  runInAction
} from "mobx";

export default class ModalStore {
  @observable
  visible: boolean = false;

  @observable
  title: string = "";

  @observable
  description: string = "";

  @observable
  handleAccept: (() => void) | undefined;

  @observable
  handleDenied: (() => void) | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  makeNewModal(
    title: string,
    description: string,
    handleAccept: () => void,
    handleDenied?: () => void
  ) {
    runInAction(() => {
      this.visible = true;
      this.title = title;
      this.description = description;
      this.handleAccept = handleAccept;
      this.handleDenied = handleDenied;
    });
  }
}
