import axios from "axios";

export const login = async (
  email: string,
  password: string
): Promise<{ token: string }> => {
  return new Promise((resolve, reject) => {
    const baseURL = process.env.REACT_APP_API_URL;

    axios({
      method: "POST",
      baseURL,
      url: "/authentication_token",
      data: { email, password },
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};
