import axios from "axios";
import { Document } from "../models/Document";

export const uploadDocument = async (
  token: string | undefined,
  doc: any | undefined
): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (token === undefined || token === "")
      reject(new Error("token not provided"));
    if (doc === undefined) reject(new Error("doc not provided"));

    const baseURL = process.env.REACT_APP_API_URL;

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };

    let formData = new FormData();
    formData.append("file", doc);

    axios({
      headers,
      method: "POST",
      baseURL,
      url: `/document_objects`,
      data: formData,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const patchUrlDoc = async (
  token: string | undefined,
  doc: any | undefined
): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (token === undefined || token === "")
      reject(new Error("token not provided"));
    if (doc === undefined) reject(new Error("doc not provided"));

    const baseURL = process.env.REACT_APP_API_URL;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios({
      headers,
      method: "PATCH",
      baseURL,
      url: `/${doc["@id"]}`,
      data: { contentUrl: doc["contentUrl"] },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createDocument = async (
  token: string | undefined,
  document: any | undefined
): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (token === undefined || token === "")
      reject(new Error("token not provided"));
    if (document === undefined) reject(new Error("document not provided"));

    const baseURL = process.env.REACT_APP_API_URL;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios({
      headers,
      method: "POST",
      baseURL,
      url: `/documents`,
      data: document,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const patchDoc = async (
  token: string | undefined,
  doc: Document | undefined
): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (token === undefined || token === "")
      reject(new Error("token not provided"));
    if (doc === undefined) reject(new Error("doc not provided"));

    const baseURL = process.env.REACT_APP_API_URL;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios({
      headers,
      method: "PATCH",
      baseURL,
      url: `/documents/${doc?.id}`,
      data: { ...doc },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
