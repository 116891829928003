import { action, makeAutoObservable, observable } from "mobx";
import { toast } from "react-toastify";
import { FirstStepGroupDTO } from "../../../components/Group/GroupCreation/FirstStep/FirstStep.type";
import { createGroup } from "../../api/groups";
import { createPicture, patchUrl } from "../../api/picture";
import { GroupDTO } from "../../models/Group";
import AccomodationStore from "../accomodation/AccomodationStore";
import AuthStore from "../auth/AuthStore";
import GroupStore from "../group/GroupStore";
import LoaderStore from "../loader/LoaderStore";
import UserStore from "../user/UserStore";

export default class CreateGroupStore {
  public loader: LoaderStore;

  public auth: AuthStore;

  public user: UserStore;

  public group: GroupStore;

  public accomodation: AccomodationStore;

  @observable
  currentStep: number = 1;

  @observable
  name: string = "";

  @observable
  city: string = "";

  @observable
  description: string = "";

  @observable
  picture: any | null = null;

  @observable
  accomodations: number[] = [];

  constructor(rootStore: any) {
    this.loader = rootStore.loaderStore;
    this.auth = rootStore.authStore;
    this.user = rootStore.userStore;
    this.group = rootStore.groupStore;
    this.accomodation = rootStore.accomodationStore;

    makeAutoObservable(this);
  }

  @action
  saveForm(values: FirstStepGroupDTO) {
    this.name = values.name;
    this.city = values.city;
    this.description = values.description;
    this.picture = values.picture;

    this.nextStep();
  }

  @action
  manageAccomodation(id: number) {
    if (this.accomodations.includes(id)) {
      this.accomodations.splice(this.accomodations.indexOf(id), 1);
    } else {
      this.accomodations.push(id);
    }
  }

  @action
  nextStep() {
    this.currentStep += 1;
  }

  @action
  async createGroup() {
    this.loader.startLoading();
    try {
      let grp: GroupDTO;
      let uploadPicture;
      let newGrp;

      if (this.picture) {
        try {
          uploadPicture = await createPicture(
            this.auth.userToken,
            this.picture
          );
          await patchUrl(this.auth.userToken, uploadPicture);
        } catch (error) {
          throw new Error(
            "Une erreur est survenue lors de l'enregistrement de l'image, elle ne doit pas dépasser 10 Mo !"
          );
        }
      }

      grp = {
        name: this.name,
        city: this.city,
        description: this.description,
        picture: uploadPicture ? uploadPicture.contentUrl : "",
        users: [`/api/users/${this.user.currentUser!.ulid}`],
        accomodations: this.accomodations.map(
          (accId) => `/api/accomodations/${accId}`
        ),
      };

      try {
        newGrp = await createGroup(this.auth.userToken, grp);
      } catch (error) {
        throw new Error(
          "Une erreur est survenue lors de la création du groupe."
        );
      }

      try {
        await this.group.fetchGroupForUser(
          this.auth.userToken!,
          this.user.currentUser?.ulid!
        );
      } catch (error) {
        throw new Error(
          "Une erreur est survenue lors de la récupération des données "
        );
      }

      try {
        await this.accomodation.fetchAccomodationsWithoutGroup();
      } catch (error) {
        throw new Error(
          "Une erreur est survenue lors de la récupération des données "
        );
      }

      this.reset();
      this.loader.stopLoading();
      return newGrp;
    } catch (error) {
      this.loader.stopLoading();
      toast.error(`${error}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }

  @action
  reset() {
    this.currentStep = 1;
    this.name = "";
    this.city = "";
    this.description = "";
    this.picture = "";
    this.accomodations = [];
  }
}
