import axios from "axios";

export const createPicture = async (
  token: string | undefined,
  picture: any | undefined
): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (token === undefined || token === "")
      reject(new Error("token not provided"));
    if (picture === undefined) reject(new Error("picture not provided"));

    const baseURL = process.env.REACT_APP_API_URL;

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };

    let formData = new FormData();
    formData.append("file", picture);

    axios({
      headers,
      method: "POST",
      baseURL,
      url: `/media_objects`,
      data: formData,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const patchUrl = async (
  token: string | undefined,
  updatePictureDTO: any | undefined
): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (token === undefined || token === "")
      reject(new Error("token not provided"));
    if (updatePictureDTO === undefined)
      reject(new Error("updatePictureDTO not provided"));

    const baseURL = process.env.REACT_APP_API_URL;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios({
      headers,
      method: "PATCH",
      baseURL,
      url: `/${updatePictureDTO["@id"]}`,
      data: { contentUrl: updatePictureDTO["contentUrl"] },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
