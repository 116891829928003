import moment from "moment";
import { TenantDto } from "../../components/Accomodation/AccomodationCreation/SecondStep/SecondStep.type";

export interface ITenant {
  id: number | null;
  lastName: string;
  firstName: string;
  email: string;
  phoneNumber: string;
  entryDate: Date;
}

export class Tenant implements ITenant {
  public id: number | null;

  public lastName: string;

  public firstName: string;

  public email: string;

  public phoneNumber: string;

  public entryDate: Date;

  constructor(
    lastName: string,
    firstName: string,
    email: string,
    phoneNumber: string,
    entryDate: string
  ) {
    this.id = null;
    this.lastName = lastName;
    this.firstName = firstName;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.entryDate = moment(entryDate, "YYYY-MM-DD").toDate();
  }
}

export const tenantFactory = (tenant: TenantDto) =>
  new Tenant(
    tenant.lastName,
    tenant.firstName,
    tenant.email,
    tenant.phoneNumber,
    tenant.entryDate
  );
