import { number, object, string, mixed } from "yup";
import { EnergyClass } from "../../../../lib/models/Accomodation";

export const AccomodationCreationScheme = object().shape({
  name: string().required("Champs requis !"),
  description: string().required("Champs requis !"),
  typeId: string().required("Champs requis !"),
  address: string().required("Champs requis !"),
  floor: string().required("Champs requis !"),
  rentPrice: number().required("Champs requis !"),
  expenses: number().required("Champs requis !"),
  surface: number().required("Champs requis !"),
  deposit: number().required("Champs requis !"),
  numberPieces: string().required("Champs requis !"),
  ges: mixed<EnergyClass>().oneOf(Object.values(EnergyClass)),
  energeticClass: mixed<EnergyClass>().oneOf(Object.values(EnergyClass)),
  furnished: string().required("Champs requis !"),
  groupId: string(),
  picture: string()
});
