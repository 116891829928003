import { action, makeAutoObservable } from "mobx";
import { patchRent } from "../../api/rent";
import { Rent } from "../../models/Rent";
import AccomodationStore from "../accomodation/AccomodationStore";
import AuthStore from "../auth/AuthStore";
import GroupStore from "../group/GroupStore";
import LoaderStore from "../loader/LoaderStore";
import UserStore from "../user/UserStore";

export default class RentStore {
  public auth: AuthStore;

  public group: GroupStore;

  public accomodation: AccomodationStore;

  public user: UserStore;

  public loader: LoaderStore;

  constructor(rootStore: any) {
    this.auth = rootStore.authStore;
    this.loader = rootStore.loaderStore;
    this.group = rootStore.groupStore;
    this.accomodation = rootStore.accomodationStore;
    this.user = rootStore.userStore;

    makeAutoObservable(this);
  }

  @action
  async updateRentStatus(updatedRent: Rent) {
    try {
      this.loader.startLoading();

      await patchRent(this.auth.userToken, updatedRent);
      await this.accomodation.refreshCurrentAcc();
      await this.group.fetchGroupForUser(
        this.auth.userToken!,
        this.user.currentUser?.ulid!
      );

      this.loader.stopLoading();
    } catch (error) {
      this.loader.stopLoading();
      console.log("error", error);
    }
  }
}
