import React from "react";
import layoutBg from "../assets/images/layout-bg.svg";
import logoUser from "../assets/images/logo-user.jpg";
import { Field, Formik } from "formik";
import { useAuthStore } from "../lib/store/auth/useAuthStore";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";

type FormValues = { email: string; password: string };

const LoginFormScheme = object().shape({
  email: string()
    .email("Ce champ doit correspondre à un e-mail !")
    .required("Champs requis !"),
  password: string().required("Champs requis !"),
});

export const LoginScreen = observer(() => {
  const auth = useAuthStore();
  const navigate = useNavigate();

  const handleSubmit = async (values: FormValues) => {
    const { email, password } = values;
    if (!email || !password) return;
    try {
      const isLogged = await auth.login(email, password);
      isLogged && navigate("/accomodations");
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  return (
    <div>
      <div className="loginWrapper">
        <div className="loginForm">
          <div className="loginFormContent">
            <p className="loginFormContentLogo">
              <a href=".">
                <img src={logoUser} alt="" />
              </a>
            </p>
            <h2>Se connecter</h2>
            <p>Gérez votre propriétés et vos locataires sans prise de têtes </p>

            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={LoginFormScheme}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                touched,
                handleSubmit,
                isSubmitting,
                isValid,
                initialErrors,
                dirty,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="formField">
                    <Field
                      type="email"
                      name="email"
                      id="loginID"
                      placeholder="Identifiant"
                    />
                    <span className="field-required">
                      {errors.email && touched.email && errors.email}
                    </span>
                  </div>
                  <div className="formField">
                    <Field
                      type="password"
                      name="password"
                      id="loginPassword"
                      placeholder="Mot de passe"
                      autoComplete="password"
                    />
                    <span className="field-required">
                      {errors.password && touched.password && errors.password}
                    </span>
                    <a href=".">Mot de passe oublié ?</a>
                  </div>
                  <div className="formField">
                    <button
                      type="submit"
                      disabled={
                        isSubmitting ||
                        !isValid ||
                        !!initialErrors.email ||
                        !!initialErrors.password ||
                        !dirty
                      }
                    >
                      Se connecter
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
        <div className="loginLayout">
          <img src={layoutBg} alt="" />
          <h1>Argent, Logement</h1>
        </div>
      </div>
    </div>
  );
});
