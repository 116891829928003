import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { fetchTags } from "../../api/tags";
import { Tag } from "../../models/Tag";
import AuthStore from "../auth/AuthStore";

export default class TagStore {
  public auth: AuthStore;

  @observable
  tags: Tag[] | [] = [];

  constructor(rootStore: any) {
    this.auth = rootStore.authStore;

    makeAutoObservable(this);
  }

  @action
  async fetch() {
    try {
      const tags: any = await fetchTags(this.auth.userToken);

      runInAction(() => {
        this.tags = tags["hydra:member"];
      });
    } catch (error) {
      console.log("fetchGroups", error);
    }
  }
}
