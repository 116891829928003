import React, { FC } from "react";
import { useMachine } from "@xstate/react";
import moment from "moment";
import { paid } from "../../../../lib/constants/paiementStatus";
import { Rent } from "../../../../lib/models/Rent";
import { rentStatusMachine } from "../../../../lib/stateMachine/rentStatus/rentStatus";
import { AccomodationRentsItemComponent } from "./AccomodationRentsItem.type";
import { useRentStore } from "../../../../lib/store/rent/useRentStore";
import { useModalStore } from "../../../../lib/store/modal/ModalStore";

export const AccomodationRentsItem: FC<AccomodationRentsItemComponent> = ({
  rent,
}) => {
  const rentStore = useRentStore();
  const modal = useModalStore();

  const [current, send] = useMachine(rentStatusMachine, {
    actions: {
      load: () =>
        send("INIT", {
          value: {
            date: moment(rent.initialDate),
            isPayed: rent.payed,
          },
        }),
    },
  });

  const { label, slug } = current.context.paiementStatus;

  const handleMakeModal = () => {
    modal.makeNewModal(
      "Voulez-vous vraiment effectué cette action ?",
      "Souhaitez-vous changer le status de paiement en non payé ?",
      handleUpdate
    );
  };

  const handleUpdate = async () => {
    send("TOGGLE", {
      value: { date: moment(rent.initialDate) },
    });

    const updateRent: Rent = {
      ...rent,
      payed: current.context.paiementStatus === paid ? true : false,
    };

    await rentStore.updateRentStatus(updateRent);
  };

  return (
    <li className="tenantRentsListItem">
      <label>{moment(rent.initialDate).format("DD/MM/YYYY")}</label>
      <span
        className={`status status--${slug}`}
        onClick={
          current.context.paiementStatus === paid
            ? handleMakeModal
            : handleUpdate
        }
      >
        {label}
      </span>
    </li>
  );
};
