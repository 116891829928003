import axios from "axios";
import { History, HistoryDTO } from "../models/History";

export const createHistory = async (
  token: string | undefined,
  history: HistoryDTO | undefined
): Promise<History> => {
  return new Promise((resolve, reject) => {
    if (token === undefined || token === "")
      reject(new Error("token not provided"));
    if (history === undefined) reject(new Error("history not provided"));

    const baseURL = process.env.REACT_APP_API_URL;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios({
      headers,
      method: "POST",
      baseURL,
      url: `/histories`,
      data: history,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
