import { Document } from "./Document";
import { Group } from "./Group";
import { Tenant } from "./Tenant";
import { Rent } from "./Rent";
import { Type } from "./Type";
import { User } from "./User";
import { History } from "./History";

export interface Accomodation {
  id: number;
  name: string;
  picture: string;
  address: string;
  floor: number;
  rentPrice: number;
  expenses: number;
  surface: number;
  deposit: number;
  description: string;
  numberPieces: number;
  ges: EnergyClass;
  energyClass: EnergyClass;
  furnished: boolean;
  type: Type;
  status: boolean;
  user: User;
  linkedGroup: Group | null;
  tenant: Tenant;
  rents: Rent[];
  documents: Document[];
  histories: History[];
}

export enum EnergyClass {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G",
  "N/A" = "N/A",
}

export interface AccomodationDTO {
  name: string;
  picture?: any;
  address: string;
  description: string;
  floor: number;
  rentPrice: number;
  expenses: number;
  surface: number;
  deposit: number;
  ges: EnergyClass;
  energyClass: EnergyClass;
  furnished: boolean;
  linkedGroup?: string;
  type: string;
  tenant?: string | Omit<Tenant, "id"> | null;
  creator: string;
  status: boolean;
  numberPieces: number;
  documents?: string[];
}
