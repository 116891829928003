import { fetchAccomodations } from "../api/accomodations";
import { Accomodation } from "../models/Accomodation";

export const getAccomodationWithoutGroup = async (
  userToken: string,
  ulid: string
) => {
  const acc: any = await fetchAccomodations(userToken, ulid);
  return acc["hydra:member"].filter((acc: Accomodation) => !acc.linkedGroup);
};
