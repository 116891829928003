import React, { FC } from "react";
import i18n from "i18n-js";
import { AccomodationInfosComponent } from "./AccomodationInfos.type";
import stairs from "../../../assets/images/details/stairs.svg";
import money from "../../../assets/images/details/money.svg";
import surface from "../../../assets/images/details/surface.svg";
import charges from "../../../assets/images/details/charges.svg";
import rooms from "../../../assets/images/details/rooms.svg";
import caution from "../../../assets/images/details/caution.svg";
import gaz from "../../../assets/images/details/gaz.svg";
import energy from "../../../assets/images/details/energy.svg";
import furniture from "../../../assets/images/details/furniture.svg";

export const AccomodationInfo: FC<AccomodationInfosComponent> = ({
  accomodation,
}) => {
  return (
    <>
      <h3>{i18n.t("description")}</h3>
      <p>{accomodation.description}</p>

      <ul className="rentDetails">
        <li>
          <img src={stairs} alt="" />
          <p>
            <span>{i18n.t("floor")}</span>
            <span>
              <strong>
                {i18n.t("current_floor", {
                  count: accomodation.floor,
                })}
              </strong>
            </span>
          </p>
        </li>
        <li>
          <img src={money} alt="" />
          <p>
            <span>{i18n.t("rent")}</span>
            <span>
              <strong>{accomodation.rentPrice} €</strong>
            </span>
          </p>
        </li>
        <li>
          <img src={surface} alt="" />
          <p>
            <span>{i18n.t("surface")}</span>
            <span>
              <strong>{accomodation.surface} m2</strong>
            </span>
          </p>
        </li>
        <li>
          <img src={charges} alt="" />
          <p>
            <span>{i18n.t("expenses")}</span>
            <span>
              <strong>{accomodation.expenses} €</strong>
            </span>
          </p>
        </li>
        <li>
          <img src={rooms} alt="" />
          <p>
            <span>{i18n.t("number_of_pieces")}</span>
            <span>
              <strong>{accomodation.numberPieces}</strong>
            </span>
          </p>
        </li>
        <li>
          <img src={caution} alt="" />
          <p>
            <span>{i18n.t("deposit")}</span>
            <span>
              <strong>{accomodation.deposit} €</strong>
            </span>
          </p>
        </li>
        <li>
          <img src={gaz} alt="" />
          <p>
            <span>{i18n.t("greenhouse_gases")}</span>
            <span>
              <strong>{accomodation.ges}</strong>
            </span>
          </p>
        </li>
        <li>
          <img src={energy} alt="" />
          <p>
            <span>{i18n.t("energy_class")}</span>
            <span>
              <strong>{accomodation.energyClass}</strong>
            </span>
          </p>
        </li>
        <li>
          <img src={furniture} alt="" />
          <p>
            <span>{i18n.t("furnished")}</span>
            <span>
              <strong>
                {accomodation.furnished ? i18n.t("yes") : i18n.t("no")}
              </strong>
            </span>
          </p>
        </li>
      </ul>
    </>
  );
};
