import React, { useEffect, useState } from "react";
import i18n from "i18n-js";
import { Link, Outlet } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Accordian } from "../components/Accordian/Accordian";
import { useGroupStore } from "../lib/store/group/useGroupStore";
import { Group } from "../lib/models/Group";
import { useAccomodationStore } from "../lib/store/accomodation/useAccomodationStore";
import { useTagStore } from "../lib/store/tag/useTagStore";
import { useTenantStore } from "../lib/store/tenant/useTenantStore";
import { reaction } from "mobx";
import { useLoaderStore } from "../lib/store/loader/useLoaderStore";

export const AccomodationsListScreen = observer(() => {
  const groupStore = useGroupStore();

  const loader = useLoaderStore()
  const tags = useTagStore();
  const accomodation = useAccomodationStore();
  const tenant = useTenantStore();

  useEffect(() => {
    (async () => {
      loader.startLoading();
      await tags.fetch();
      await accomodation.fetchAccomodationTypes();
      await accomodation.fetchAccomodationsWithoutGroup();
      await tenant.fetchTenants();
      loader.stopLoading();
    })();
  }, []);

  const [filter, setFilter] = useState<string>("");
  const [filteredGroups, setFilteredGroups] = useState<Group[] | []>([]);

  const makeFilter = (grps: Group[]) => {
    setFilteredGroups(
      grps
        .flatMap((grp) => {
          const newAccs = grp.accomodations
            .filter(
              (f) =>
                filter === "" ||
                f.name.toLowerCase().includes(filter.toLowerCase())
            )
            .map((f) => f)
            .sort((a, b) => a.name.localeCompare(b.name));

          if (newAccs.length > 0) {
            return { ...grp, accomodations: newAccs };
          }

          return [];
        })
        .sort((a, b) => a.name.localeCompare(b.name))
    );
  };

  useEffect(
    () =>
      reaction(
        () => groupStore.groups,
        () => {
          makeFilter(groupStore.groups!);
        }
      ),
    []
  );

  useEffect(() => {
    makeFilter(groupStore.groups!);
  }, [filter]);

  return (
    <>
      <div className="accomodations">
        <div className="accomodationsHeader">
          <h2 className="accomodationsTitle">
            {i18n.t("my_accomodations")}
            <Link to="createAccomodation" className="createAccomodation">
              <i className="fa-solid fa-plus" />
            </Link>
          </h2>

          <div className="accomodationsForm">
            <input
              type="text"
              placeholder="Rechercher"
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
        </div>
        <div className="accomodationsList">
          {filteredGroups.map((group) => (
            <Accordian key={group.id} group={group} />
          ))}
        </div>
      </div>

      <div className="mainContentWrapper">
        <Outlet />
      </div>
    </>
  );
});
