import React from "react";
import i18n from "i18n-js";
import ornement from "../assets/images/ornement.svg";
import { AccomodationCreation } from "../components/Accomodation/AccomodationCreation/AccomodationCreation";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useCreateAccomodationStore } from "../lib/store/createAccomodation/useCreateGroupStore";

export const AccomodationCreationScreen = observer(() => {
  const navigate = useNavigate();
  const createAccomodation = useCreateAccomodationStore();

  return (
    <div className="mainContentBody">
      <h1>
        {i18n.t("creation")}
        <span className="step">
          <img src={ornement} alt="" />
          {createAccomodation.currentStep}
        </span>
        /3
      </h1>

      {createAccomodation.currentStep === 1 ? (
        <>
          <div className="formField formField--radio">
            <input
              type="radio"
              id="groupe"
              name="creation"
              onClick={() => navigate("../createGroup")}
            />
            <label htmlFor="groupe">{i18n.t("group")}</label>

            <input type="radio" id="logement" name="creation" defaultChecked />
            <label htmlFor="logement">{i18n.t("accomodation")}</label>
          </div>
          <AccomodationCreation.FirstStep />
        </>
      ) : createAccomodation.currentStep === 2 ? (
        <AccomodationCreation.SecondStep />
      ) : (
        <AccomodationCreation.ThirdStep />
      )}
    </div>
  );
});
