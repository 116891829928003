import { Accomodation } from "./Accomodation";

export interface Rent {
  id: number;
  month: Month;
  payed: boolean;
  initialDate: Date;
  amount: number;
  accomodation: Accomodation;
}

export interface RentDTO {
  month: Month;
  payed: boolean;
  initialDate: Date;
  amount: number;
  accomodation: string;
}

export enum Month {
  january = "janvier",
  february = "février",
  march = "mars",
  april = "avril",
  may = "mai",
  june = "juin",
  july = "juillet",
  august = "août",
  september = "septembre",
  october = "octobre",
  november = "novembre",
  december = "décembre",
}
