import axios from "axios";
import { User } from "../models/User";

export const getUser = async (ulid: string, token: string): Promise<User> => {
  return new Promise((resolve, reject) => {
    const baseURL = process.env.REACT_APP_API_URL;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios({
      headers,
      method: "GET",
      baseURL,
      url: `/users/${ulid}`,
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};
