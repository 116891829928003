import React from "react";
import { observer } from "mobx-react-lite";
import { useLoaderStore } from "../../lib/store/loader/useLoaderStore";

export const Loader = observer(() => {
  const loader = useLoaderStore();

  return (
    <>
      {loader.loading && (
        <div className="overlay">
          <div className="loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      )}
    </>
  );
});
