import { assign, createMachine } from "xstate";

export interface AccomodationPageContext {
  currentPage: "infos" | "docs" | "history";
}

export const accomodationPageMachine = createMachine<AccomodationPageContext>({
  id: "accomodation-page",
  initial: "infos",
  context: {
    currentPage: "infos",
  },
  states: {
    infos: {
      on: {
        INFOS: {
          actions: assign({
            currentPage: (ctx) => (ctx.currentPage = "infos"),
          }),
        },
        DOCUMENTS: {
          actions: assign({
            currentPage: (ctx) => (ctx.currentPage = "docs"),
          }),
        },
        HISTORY: {
          actions: assign({
            currentPage: (ctx) => (ctx.currentPage = "history"),
          }),
        },
      },
    },
  },
});
