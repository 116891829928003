import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { observer } from "mobx-react-lite";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { initLocalization } from "./lib/i18n/localization";
import { AccomodationScreen } from "./screens/AccomodationScreen";
import { AccomodationsListScreen } from "./screens/AccomodationsListScreen";
import { DashboardScreen } from "./screens/DashboardScreen";
import { HomeScreen } from "./screens/HomeScreen";
import { LoginScreen } from "./screens/LoginScreen";
import { useAuthStore } from "./lib/store/auth/useAuthStore";
import { AccomodationCreationScreen } from "./screens/AccomodationCreationScreen";
import { GroupCreationScreen } from "./screens/GroupCreationScreen";
import { Loader } from "./components/Loader/Loader";
import { ConsentModal } from "./components/Modal/ConsentModal/ConsentModal";
import { StoreProvider } from "./lib/store/StoreProvider";
import { ProtectedRoute } from "./components/ProtectedRoutes/ProtectedRoutes";

export const App = observer(() => {
  initLocalization();

  const auth = useAuthStore();

  return (
    <BrowserRouter>
      <StoreProvider>
        <Routes>
          <Route
            index
            element={auth.isLogged ? <HomeScreen /> : <LoginScreen />}
          />
          <Route
            path="/"
            element={
              <ProtectedRoute redirectPath="/login" isAllowed={auth.isLogged}>
                <HomeScreen />
              </ProtectedRoute>
            }
          >
            <Route path="dashboard" element={<DashboardScreen />} />
            <Route path="accomodations" element={<AccomodationsListScreen />}>
              <Route path=":accomodationId" element={<AccomodationScreen />} />
              <Route
                path="createAccomodation"
                element={<AccomodationCreationScreen />}
              />
              <Route path="createGroup" element={<GroupCreationScreen />} />
            </Route>
          </Route>
          <Route
            path="/login"
            element={
              <ProtectedRoute redirectPath="/" isAllowed={!auth.isLogged}>
                <LoginScreen />
              </ProtectedRoute>
            }
          />
        </Routes>

        <ToastContainer />
        <Loader />
        <ConsentModal />
      </StoreProvider>
    </BrowserRouter>
  );
});
