import React, { FC } from "react";
import i18n from "i18n-js";
import { AccomodationHistoryItem } from "../Item/AccomodationHistoryItem";
import { AccomodationHistoryListComponent } from "./AccomodationHistoryList.type";

export const AccomodationHistoryList: FC<AccomodationHistoryListComponent> = ({
  histories,
}) => {
  return (
    <>
      <h3>{i18n.t("history")}</h3>

      {histories.length > 0 ? (
        <div className="historicalList">
          <a href="#addHistoricalItem" className="historicalListAddItem">
            <i className="fas fa-plus"></i>
          </a>

          {histories.map((history) => (
            <AccomodationHistoryItem key={history.id} history={history} />
          ))}
        </div>
      ) : (
        <p>{i18n.t("no_history")}</p>
      )}
    </>
  );
};
