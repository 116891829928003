import React, { FC } from "react";
import i18n from "i18n-js";
import { TenantsInfosComponent } from "./TenantsInfos.type";
import moment from "moment";

export const TenantsInfos: FC<TenantsInfosComponent> = ({ accomodation }) => {
  const { tenant } = accomodation;

  return (
    <>
      <div className="tenantInfosHeader">
        <p className="tenantInfosHeaderThumb">
          <img src="https://picsum.photos/50/50" alt="" />
        </p>
        <p className="tenantInfosHeaderContent">
          <span>
            {accomodation.status
              ? `${tenant.firstName}
                ${tenant.lastName}`
              : i18n.t("without_tenant")}
          </span>
          {i18n.t("tenant")}
        </p>
      </div>
      <div className="tenantInfosList">
        {accomodation.status && (
          <>
            <div className="tenantInfosListItem">
              <label>{i18n.t("arrival_date")}</label>
              <span>{moment(tenant?.entryDate).format('DD/MM/YYYY')}</span>
            </div>
            <div className="tenantInfosListItem">
              <label>{i18n.t("phone")}</label>
              <span>{tenant?.phoneNumber}</span>
            </div>
            <div className="tenantInfosListItem">
              <label>{i18n.t("email")}</label>
              <span>{tenant?.email}</span>
            </div>
          </>
        )}
        <div className="tenantInfosListItem">
          <label>{i18n.t("rent_per_months")}</label>
          <span>
            <strong>{accomodation.rentPrice}€</strong>
          </span>
        </div>
      </div>
    </>
  );
};
