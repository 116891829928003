import { createMachine, assign } from "xstate";
import moment from "moment";
import { RentStatusContext } from "./rentStatus.type";
import { late, unpaid, paid, pending } from "../../constants/paiementStatus";

export const rentStatusMachine = createMachine<RentStatusContext>({
  id: "toggle",
  initial: "boot",
  context: {
    paiementStatus: { label: "", slug: "" },
  },
  states: {
    boot: {
      entry: "load",
      on: {
        INIT: [
          {
            target: "paid",
            cond: (_, event) => event.value.isPayed,
          },
          {
            target: "pending",
            cond: (_, event) =>
              moment(Date.now()).diff(event.value.date, "days") < 1,
          },
          {
            target: "late",
            cond: (_, event) =>
              moment(Date.now()).diff(event.value.date, "days") < 30,
          },
          {
            target: "unpaid",
          },
        ],
      },
    },
    pending: {
      entry: assign({
        paiementStatus: (ctx) => (ctx.paiementStatus = pending),
      }),
      on: {
        TOGGLE: [
          {
            target: "paid",
          },
        ],
      },
    },
    late: {
      entry: assign({
        paiementStatus: (ctx) => (ctx.paiementStatus = late),
      }),
      on: { TOGGLE: "paid" },
    },
    unpaid: {
      entry: assign({
        paiementStatus: (ctx) => (ctx.paiementStatus = unpaid),
      }),
      on: { TOGGLE: "paid" },
    },
    paid: {
      entry: assign({
        paiementStatus: (ctx) => (ctx.paiementStatus = paid),
      }),
      on: {
        TOGGLE: [
          {
            target: "pending",
            cond: (_, event) =>
              moment(Date.now()).diff(event.value.date, "days") < 1,
          },
          {
            target: "late",
            cond: (_, event) =>
              moment(Date.now()).diff(event.value.date, "days") < 30,
          },
          {
            target: "unpaid",
          },
        ],
      },
    },
  },
});
