import React from "react";
import { Link } from "react-router-dom";
import { useAuthStore } from "../../lib/store/auth/useAuthStore";

import dashboard from "../../assets/images/dashboard.svg";
import accomodation from "../../assets/images/key.svg";
import logout from "../../assets/images/logout.svg";
import logo from "../../assets/images/logo-user.jpg";

export const Navigation = () => {
  const auth = useAuthStore();

  return (
    <div className="nav">
      <p className="navLogo">
        <img src={logo} alt="" />
      </p>
      <ul className="navList">
        <li className="navListItem navListItem--board">
          <Link to="dashboard">
            <img src={dashboard} alt="" />
          </Link>
        </li>
        <li className="navListItem navListItem--keys">
          <Link to="accomodations">
            <img src={accomodation} alt="" />
          </Link>
        </li>
      </ul>
      <p className="navLogout">
        <Link to="/" onClick={() => auth.logout()}>
          <img src={logout} alt="" />
        </Link>
      </p>
    </div>
  );
};
