import { object, string } from "yup";

export const TenantCreationScheme = object().shape({
  firstName: string(),
  lastName: string(),
  email: string().email("Ce champ doit correspondre à un e-mail !"),
  phone: string(),
  entryDate: string(),
  entryDateUpdate: string(),
});
