import axios from "axios";
import { Tenant } from "../models/Tenant";

export const fetchTenants = async (
  token: string | undefined
): Promise<Tenant[]> => {
  return new Promise((resolve, reject) => {
    if (token === undefined || token === "")
      reject(new Error("token not provided"));

    const baseURL = process.env.REACT_APP_API_URL;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios({
      headers,
      method: "GET",
      baseURL,
      url: `/tenants`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchTenantById = async (
  id: Tenant["id"]
): Promise<Tenant | null> => {
  return new Promise((resolve, reject) => {
    axios
      .get(`http://127.0.0.1:8001/api/tenants/${id}`)
      .then((response) => {
        if (response.data.length === 0) {
          resolve(null);
          return;
        }
        resolve(response.data);
      })
      .catch((error) => reject(error));
  });
};

export const patchTenant = async (
  token: string | undefined,
  id: Tenant["id"],
  tenant: Partial<Tenant>
): Promise<Tenant> => {
  return new Promise((resolve, reject) => {
    if (token === undefined || token === "")
      reject(new Error("token not provided"));
    if (!id) reject(new Error("id not provided"));

    const baseURL = process.env.REACT_APP_API_URL;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios({
      headers,
      method: "PATCH",
      baseURL,
      url: `/tenants/${id}`,
      data: tenant,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
