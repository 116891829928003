import { action, makeAutoObservable, observable, runInAction } from "mobx";
import moment from "moment";
import { AccomodationCreationDTO } from "../../../components/Accomodation/AccomodationCreation/FirstStep/FirstStep.type";
import { TenantDto } from "../../../components/Accomodation/AccomodationCreation/SecondStep/SecondStep.type";
import { createAccomodation } from "../../api/accomodations";
import {
  uploadDocument,
  patchUrlDoc,
  createDocument,
} from "../../api/document";
import { createHistory } from "../../api/history";
import { createPicture, patchUrl } from "../../api/picture";
import { patchTenant } from "../../api/tenant";
import { AccomodationDTO } from "../../models/Accomodation";
import { HistoryDTO } from "../../models/History";
import { DocumentDTO } from "../../models/Document";
import { Tenant, tenantFactory } from "../../models/Tenant";
import AccomodationStore from "../accomodation/AccomodationStore";
import AuthStore from "../auth/AuthStore";
import GroupStore from "../group/GroupStore";
import UserStore from "../user/UserStore";
import { fileConvertSize, splitNameExtention } from "../../utils/fileTools";
import { toast } from "react-toastify";
import { Month, RentDTO } from "../../models/Rent";
import { months } from "../../constants/enumIteration";
import { createRent } from "../../api/rent";
import LoaderStore from "../loader/LoaderStore";

export default class CreateAccomodationStore {
  public loader: LoaderStore;

  public auth: AuthStore;

  public user: UserStore;

  public group: GroupStore;

  public accomodation: AccomodationStore;

  @observable
  currentStep: number = 1;

  @observable
  newAccomodation: AccomodationCreationDTO | null = null;

  @observable
  picture: any | null = null;

  @observable
  tenantId: number | null = null;

  @observable
  tenantEntryDate: Date | null = null;

  @observable
  newTenant: Omit<Tenant, "id"> | null = null;

  @observable
  documents: Array<any> | null = null;

  constructor(rootStore: any) {
    this.loader = rootStore.loaderStore;
    this.auth = rootStore.authStore;
    this.user = rootStore.userStore;
    this.group = rootStore.groupStore;
    this.accomodation = rootStore.accomodationStore;

    makeAutoObservable(this);
  }

  @action
  addAccomodation(accomodationDto: AccomodationCreationDTO) {
    this.newAccomodation = accomodationDto;
    this.addPicture(accomodationDto.picture);
    this.nextStep();
  }

  @action
  addPicture(pictureUrl: string) {
    runInAction(() => {
      this.picture = pictureUrl;
    });
  }

  @action
  manageTenant(id: number) {
    runInAction(() => {
      this.newTenant = null;
      this.tenantId = id;
    });
  }

  @action
  createTenant(tenantDto: TenantDto | null) {
    if (tenantDto) {
      runInAction(() => {
        this.tenantId = null;
        this.newTenant = tenantFactory(tenantDto);
      });
    }
    this.nextStep();
  }

  @action
  updateTenant(partialTenant: Partial<Tenant>) {
    runInAction(() => {
      this.tenantEntryDate = partialTenant.entryDate!;
    });
    this.nextStep();
  }

  @action
  async addDocuments(documents: Array<any>) {
    if (documents.length > 0) {
      runInAction(() => {
        this.documents = documents;
      });
    }
    try {
      return await this.createAccomodation();
    } catch (error) {
      return error;
    }
  }

  @action
  async createAccomodation() {
    if (this.newAccomodation) {
      this.loader.startLoading();
      try {
        let acc: AccomodationDTO;
        let docArr: any[] = [];
        let uploadPicture;
        let newAcc: any;

        if (this.picture) {
          try {
            uploadPicture = await createPicture(
              this.auth.userToken,
              this.picture
            );
            await patchUrl(this.auth.userToken, uploadPicture);
          } catch (error) {
            throw new Error(
              "Une erreur est survenue lors de l'enregistrement de l'image, elle ne doit pas dépasser 10 Mo !"
            );
          }
        }

        if (this.documents && this.documents.length > 0) {
          await Promise.all(
            this.documents.map(async (doc) => {
              try {
                const uploadDoc = await uploadDocument(
                  this.auth.userToken,
                  doc.file
                );

                await patchUrlDoc(this.auth.userToken, uploadDoc);

                const docDTO: DocumentDTO = {
                  name: splitNameExtention(doc.file).name,
                  archive: doc.archived,
                  description: "",
                  extention: splitNameExtention(doc.file).extention,
                  size: fileConvertSize(doc.file.size)!,
                  tags: [],
                  uploadDate: new Date(),
                  url: uploadDoc.contentUrl,
                };

                const newDoc = await createDocument(
                  this.auth.userToken,
                  docDTO
                );
                docArr.push(newDoc["@id"]);
              } catch (error) {
                throw error;
              }
            })
          ).catch(() => {
            throw new Error(
              "Une erreur est survenue lors de l'enregistrement des fichiers, la taille ne doit pas dépasser 10 Mo par fichier !"
            );
          });
        }

        acc = {
          ...this.newAccomodation,
          creator: `/api/users/${this.user.currentUser!.ulid}`,
          linkedGroup: this.newAccomodation.groupId
            ? `/api/groups/${this.newAccomodation.groupId}`
            : undefined,
          type: `/api/accomodation_types/${this.newAccomodation.typeId}`,
          status: !!(this.tenantId || this.newTenant),
          tenant: this.tenantId
            ? `/api/tenants/${this.tenantId}`
            : this.newTenant,
          floor: Number(this.newAccomodation.floor),
          furnished: this.newAccomodation.furnished === "true" ? true : false,
          numberPieces: Number(this.newAccomodation.numberPieces),
          picture: uploadPicture ? uploadPicture.contentUrl : "",
          documents: docArr.map((id) => `/api/${id}`),
        };

        try {
          newAcc = await createAccomodation(this.auth.userToken, acc);
        } catch (error) {
          throw new Error(
            "Une erreur est survenue lors de la création du logement."
          );
        }

        if (!!this.tenantId) {
          try {
            await patchTenant(this.auth.userToken, this.tenantId, {
              entryDate: this.tenantEntryDate!,
            });
          } catch (error) {
            throw new Error(
              "Une erreur est survenue lors de la modification du locataire."
            );
          }
        }
        if (!!(this.tenantId || this.newTenant)) {
          try {
            const hist: HistoryDTO = {
              description: "Nouveau locataire",
              startDate: new Date(),
              type: "/api/history_types/1",
              tenant: `/api/${newAcc.tenant["@id"]}`,
              accomodation: `/api/${newAcc["@id"]}`,
              endDate: null,
            };
            await createHistory(this.auth.userToken, hist);
          } catch (error) {
            throw new Error(
              "Une erreur est survenue lors de l'ajout de l'historique."
            );
          }

          try {
            const rent: RentDTO = {
              month: Month[months[moment(new Date()).month()]],
              amount: newAcc.rentPrice,
              initialDate: newAcc.tenant.entryDate,
              payed: false,
              accomodation: `/api/${newAcc["@id"]}`,
            };

            await createRent(this.auth.userToken, rent);
          } catch (error) {
            throw new Error(
              "Une erreur est survenue lors de l'ajout du loyer."
            );
          }
        }

        try {
          await this.group.fetchGroupForUser(
            this.auth.userToken!,
            this.user.currentUser?.ulid!
          );
          if (!acc.linkedGroup) {
            await this.accomodation.fetchAccomodationsWithoutGroup();
          }
        } catch (error) {
          throw new Error(
            "Une erreur est survenue lors de la récupération des données "
          );
        }

        this.reset();
        this.loader.stopLoading();
        return newAcc.id;
      } catch (error) {
        this.loader.stopLoading();
        toast.error(`${error}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  }

  @action
  nextStep() {
    this.currentStep += 1;
  }

  @action
  reset() {
    this.currentStep = 1;
    this.newAccomodation = null;
    this.tenantId = null;
    this.newTenant = null;
    this.documents = null;
  }
}
