import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";
import jwtDecode from "jwt-decode";
import { getUser } from "../../api/users";
import { User } from "../../models/User";
import { toast } from "react-toastify";
import GroupStore from "../group/GroupStore";
import { getAccomodationWithoutGroup } from "../../utils/getAccomodationWithoutGroup";

export default class UserStore {
  public groupStore: GroupStore;

  @observable
  currentUser: User | null = null;

  constructor(private readonly rootStore: any) {
    this.groupStore = rootStore.groupStore;

    makeAutoObservable(this);

    makePersistable(this, {
      name: "UserStore",
      properties: ["currentUser"],
      storage: window.localStorage,
    });
  }

  @action
  async getUser(userToken: string) {
    try {
      const usr: any = jwtDecode(userToken);
      const user = await getUser(usr.ulid, userToken);

      const accWithoutGroups = await getAccomodationWithoutGroup(
        userToken,
        user.ulid
      );
      runInAction(() => {
        this.currentUser = user;
        const grps = user.groups;

        if (accWithoutGroups.length > 0) {
          grps.push({
            id: 0,
            name: "Non groupé",
            city: "N/A",
            description: "Logement n'ayant pas de groupe",
            accomodations: accWithoutGroups,
            picture: "https://picsum.photos/300/200",
          });
        }

        this.groupStore.setGroups(grps);
      });

      return toast.info(`Bienvenue ${user.firstname} ${user.lastname} !`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
      });
    } catch (error) {
      console.log("error", error);
    }
  }
}
