import axios from "axios";
import { Group, GroupDTO } from "../models/Group";

export const fetchGroups = async (
  token: string | undefined
): Promise<Group[]> => {
  return new Promise((resolve, reject) => {
    if (token === undefined || token === "")
      reject(new Error("token not provided"));

    const baseURL = process.env.REACT_APP_API_URL;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios({
      headers,
      method: "GET",
      baseURL,
      url: `/groups`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchGroupForUser = async (
  token: string | undefined,
  ulid: string | undefined
): Promise<Group[]> => {
  return new Promise((resolve, reject) => {
    if (token === undefined || token === "")
      reject(new Error("token not provided"));

    if (ulid === undefined || ulid === "")
      reject(new Error("ulid not provided"));

    const baseURL = process.env.REACT_APP_API_URL;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios({
      headers,
      method: "GET",
      baseURL,
      url: `/users/${ulid}/groups`,
    })
      .then((response) => {
        resolve(response.data['hydra:member']);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createGroup = async (
  token: string | undefined,
  group: GroupDTO | undefined
): Promise<Group> => {
  return new Promise((resolve, reject) => {
    if (token === undefined || token === "")
      reject(new Error("token not provided"));
    if (group === undefined) reject(new Error("group not provided"));

    const baseURL = process.env.REACT_APP_API_URL;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios({
      headers,
      method: "POST",
      baseURL,
      url: `/groups`,
      data: group,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
