import axios from "axios";
import { Tag } from "../models/Tag";

export const fetchTags = async (token: string | undefined): Promise<Tag[]> => {
  return new Promise((resolve, reject) => {
    if (token === undefined || token === "")
      reject(new Error("token not provided"));

    const baseURL = process.env.REACT_APP_API_URL;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios({
      headers,
      method: "GET",
      baseURL,
      url: `/tags`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
