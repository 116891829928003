import React from "react";
import i18n from "i18n-js";
import { useAccomodationStore } from "../../../../lib/store/accomodation/useAccomodationStore";
import { useCreateGroupStore } from "../../../../lib/store/createGroup/useCreateGroupStore";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

export const SecondStep = observer(() => {
  const navigate = useNavigate();
  const createGroup = useCreateGroupStore();
  const accomodation = useAccomodationStore();

  const handleCreate = async () => {
    const newGrp = await createGroup.createGroup();

    if (newGrp) {
      if (newGrp?.accomodations && newGrp?.accomodations.length > 0) {
        navigate(`../${newGrp.accomodations[0].id}`);
      } else {
        navigate("../createAccomodation");
      }
    }
  };

  return (
    <>
      <div className="integrateAccomodations">
        <h2>{i18n.t("accomodation_integration")}</h2>
        <input type="search" placeholder="Rechercher" />
      </div>

      <div className="accomodationsWrapper">
        {accomodation.accomodationsWithoutGroup.map((acc) => (
          <div
            key={acc.id}
            className={`loopAccomodation${
              createGroup.accomodations.includes(acc.id) ? " current" : ""
            }`}
            onClick={() => createGroup.manageAccomodation(acc.id)}
          >
            <p className="loopAccomodationThumbnail">
              <img src={acc.picture || "https://picsum.photos/id/424/1920/1080"} alt="" />
            </p>
            <div className="loopAccomodationContent">
              <h3>
                {acc.type.label.toUpperCase()} - {""}
                {acc &&
                  i18n.t("current_floor", {
                    count: acc.floor,
                  })}
              </h3>
              <p className="loopAccomodationContentLocation">
                <i className="fa-solid fa-location-dot"></i> {acc.address}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="formField formField--buttons">
        <button type="submit" onClick={handleCreate}>
          {i18n.t("skip")}
        </button>
        <button type="submit" onClick={handleCreate}>
          <i className="far fa-save"></i> {i18n.t("save")}
        </button>
      </div>
    </>
  );
});
