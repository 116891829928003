import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import { AccomodationRentsItem } from "../Item/AccomodationRentsItem";
import { AccomodationRentsListComponent } from "./AccomodationRentsList.type";
import { getPaiementStatus } from "../../../../lib/utils/getPaiementStatus";
import { late, paid, unpaid } from "../../../../lib/constants/paiementStatus";

export const AccomodationRentsList: FC<AccomodationRentsListComponent> = ({
  accomodation,
}) => {
  const [amountUnpaid, setAmountUnpaid] = useState<number>(0);
  const [paimentStatus, setPaiementStatus] = useState(paid);

  useEffect(() => {
    let amount = 0;
    let currStatus = paid;
    accomodation.rents.forEach((rent) => {
      const pStatus = getPaiementStatus(moment(rent.initialDate), rent.payed);

      if (pStatus === unpaid || pStatus === late) {
        if (pStatus === late && currStatus !== unpaid) {
          currStatus = late;
        } else {
          currStatus = unpaid;
        }

        amount -= accomodation.rentPrice;
      }
    });
    setAmountUnpaid(amount);
    setPaiementStatus(currStatus);
  }, [accomodation]);

  return (
    <>
      <div className="tenantRentsHeader">
        <h4>Loyers :</h4>
        <span className={paimentStatus.slug}>{amountUnpaid} €</span>
      </div>

      <ul className="tenantRentsList">
        {accomodation.rents.map((rent) => (
          <AccomodationRentsItem key={rent.id} rent={rent} />
        ))}
      </ul>
    </>
  );
};
