import React, { FC } from "react";
import i18n from "i18n-js";
import { AccomodationHistoryItemComponent } from "./AccomodationHistoryItem.type";
import moment from "moment";

export const AccomodationHistoryItem: FC<AccomodationHistoryItemComponent> = ({
  history,
}) => {
  return history.type.id === 2 ? (
    <p className="historicalListItem">
      <span>
        {moment(history.startDate).format('DD/MM/YYYY')} {history.endDate && `- ${moment(history.endDate).format('DD/MM/YYYY')}`}
      </span>
      {history.description} - {history.type.label}
    </p>
  ) : (
    <p className="historicalListItem">
      <span>
        {moment(history.startDate).format('DD/MM/YYYY')} -{" "}
        {history.endDate ? moment(history.endDate).format('DD/MM/YYYY') : i18n.t("today")}
      </span>
      {history.tenant?.firstName} {history.tenant?.lastName} -{" "}
      {history.type.label}
    </p>
  );
};
