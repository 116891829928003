import axios from "axios";
import { Rent, RentDTO } from "../models/Rent";

export const createRent = async (
  token: string | undefined,
  rent: RentDTO | undefined
): Promise<Rent> => {
  return new Promise((resolve, reject) => {
    if (token === undefined || token === "")
      reject(new Error("token not provided"));
    if (rent === undefined) reject(new Error("rent not provided"));

    const baseURL = process.env.REACT_APP_API_URL;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios({
      headers,
      method: "POST",
      baseURL,
      url: `/rents`,
      data: rent,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const patchRent = async (
  token: string | undefined,
  rent: Rent | undefined
): Promise<Rent> => {
  return new Promise((resolve, reject) => {
    if (token === undefined || token === "")
      reject(new Error("token not provided"));
    if (rent === undefined) reject(new Error("rent not provided"));

    const baseURL = process.env.REACT_APP_API_URL;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios({
      headers,
      method: "PATCH",
      baseURL,
      url: `/rents/${rent?.id}`,
      data: { ...rent },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
