import { isNil } from "lodash";
import React, { FC } from "react";
import { rootStore, storeContext } from "./storeContext";

const StoreProvider: FC = ({ children }) => {
  if (isNil(rootStore)) return null;
  return (
    <storeContext.Provider value={rootStore}>{children}</storeContext.Provider>
  );
};

export { StoreProvider, storeContext };
