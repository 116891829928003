import React, { FC, useEffect, useState } from "react";
import i18n from "i18n-js";
import { clone, lowerCase } from "lodash";
import { AccomodationDocumentsListComponents } from "./AccomodationDocumentsList.type";
import { AccomodationDocumentItem } from "../Item/AccomodationDocumentItem";
import { Document } from "../../../../lib/models/Document";
import { useTagStore } from "../../../../lib/store/tag/useTagStore";
import { mimeTypeMap } from "../../../../lib/constants/mimeType";

export const AccomodationDocumentsList: FC<
  AccomodationDocumentsListComponents
> = ({ documents }) => {
  const tagsStore = useTagStore();
  const [filterTags, setFilterTags] = useState<string[]>([]);
  const [filterExtention, setFilterExtention] = useState<string>("");
  const [documentFiltered, setDocumentFiltered] = useState<Document[]>([]);

  const handleFilterExtention = (fltr: string) => {
    if (!filterTags.includes("extention")) {
      setFilterTags([...filterTags, "extention"]);
    }
    if (filterExtention === fltr) {
      setFilterExtention("");
      setFilterTags(filterTags.filter((f) => f !== "extention"));
    } else {
      setFilterExtention(fltr);
    }
  };

  const handleFilterTags = (fltr: string) => {
    if (fltr === "all") {
      setFilterTags([]);
      setFilterExtention("");
    } else if (filterTags.includes(fltr)) {
      setFilterTags(filterTags.filter((f) => f !== fltr));
    } else {
      setFilterTags([...filterTags, fltr]);
    }
  };

  const makeFilter = (docs: Document[], archiveMode: boolean) => {
    const newDocs = docs.flatMap((doc) => {
      const tagsLabel = doc.tags.map((tag) => tag.slug);

      const fltrArr = tagsLabel.filter((slug) => filterTags.includes(slug));

      const filterTagBis = clone(filterTags);

      if (filterTagBis.includes("extention")) {
        filterTagBis.splice(filterTagBis.indexOf("extention"), 1);
      }

      if (filterTagBis.includes("archives")) {
        filterTagBis.splice(filterTagBis.indexOf("archives"), 1);
      }

      const strictCompare = filterTagBis.every((fltr) =>
        fltrArr.includes(fltr)
      );

      if (strictCompare && doc.archive === archiveMode) {
        return doc;
      }

      return [];
    });

    return newDocs;
  };

  useEffect(() => {
    if (documents) {
      let archiveMode = filterTags.includes("archives");
      let newFilter: Document[] = documents.filter(
        (f) => f.archive === archiveMode
      );
      let fltrExt: Document[] | null = null;

      if (filterExtention.length > 0) {
        fltrExt = documents.filter((f) =>
          mimeTypeMap.get(filterExtention)?.includes(lowerCase(f.extention))
        );
      }

      if (fltrExt) {
        newFilter = makeFilter(fltrExt, archiveMode);
      } else {
        newFilter = makeFilter(documents, archiveMode);
      }
      setDocumentFiltered(newFilter);
    } else {
      setDocumentFiltered([]);
    }
  }, [documents, filterTags, filterExtention]);

  return (
    <>
      <h3>{i18n.t("tags")}</h3>
      <ul className="tags">
        <li
          className={filterTags.length === 0 ? `current` : ""}
          onClick={() => handleFilterTags("all")}
        >
          {i18n.t("all")}
        </li>
        <li
          className={`xls ${filterExtention === "xls" ? `current` : ""}`}
          onClick={() => handleFilterExtention("xls")}
        >
          XLS
        </li>
        <li
          className={`img ${filterExtention === "img" ? `current` : ""}`}
          onClick={() => handleFilterExtention("img")}
        >
          IMG
        </li>
        <li
          className={`doc ${filterExtention === "doc" ? `current` : ""}`}
          onClick={() => handleFilterExtention("doc")}
        >
          Doc
        </li>
        <li
          className={`pdf ${filterExtention === "pdf" ? `current` : ""}`}
          onClick={() => handleFilterExtention("pdf")}
        >
          Pdf
        </li>
        {tagsStore.tags.map((tag) => (
          <li
            key={tag.id}
            className={filterTags.includes(tag.slug) ? `current` : ""}
            onClick={() => handleFilterTags(tag.slug)}
          >
            {tag.label}
          </li>
        ))}
        <li
          className={`archives ${
            filterTags.includes("archives") ? `current` : ""
          }`}
          onClick={() => handleFilterTags("archives")}
        >
          {i18n.t("archives")}
        </li>
      </ul>

      <div className="documentsList">
        {documentFiltered.length > 0 ? (
          documentFiltered.map((document) => (
            <AccomodationDocumentItem key={document.id} document={document} />
          ))
        ) : (
          <p>{i18n.t("no_documents")}</p>
        )}
      </div>
    </>
  );
};
