import React, { useState } from "react";
import i18n from "i18n-js";
import { Field, Form, Formik } from "formik";
import { energyClass } from "../../../../lib/constants/enumIteration";
import { useAccomodationStore } from "../../../../lib/store/accomodation/useAccomodationStore";
import { AccomodationCreationDTO } from "./FirstStep.type";
import { useGroupStore } from "../../../../lib/store/group/useGroupStore";
import { useCreateAccomodationStore } from "../../../../lib/store/createAccomodation/useCreateGroupStore";
import { AccomodationCreationScheme } from "./FirstStep.scheme";
import placeholder from "../../../../assets/images/placeholder.png";
import { EnergyClass } from "../../../../lib/models/Accomodation";

export const FirstStep = () => {
  const accomodation = useAccomodationStore();
  const group = useGroupStore();
  const createAccomodation = useCreateAccomodationStore();
  const [tmpPicture, setTmpPicture] = useState<string | null>(null);

  return (
    <Formik
      initialValues={{
        name: "",
        description: "",
        typeId: "1",
        address: "",
        floor: "0",
        rentPrice: 0,
        expenses: 0,
        surface: 0,
        deposit: 0,
        numberPieces: "1",
        ges: EnergyClass.A,
        energyClass: EnergyClass.A,
        furnished: "false",
      }}
      onSubmit={(values: AccomodationCreationDTO) => {
        createAccomodation.addAccomodation(values);
      }}
      validationSchema={AccomodationCreationScheme}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form>
          <div className="your-file accomodationThumbnail">
            <div className="your-file-preview">
              <img src={tmpPicture ? tmpPicture : placeholder} alt="" />
            </div>
            <label className="button">Ajouter Image</label>
            <input
              id="picture"
              name="picture"
              type="file"
              accept="image/*"
              onChange={(event) => {
                setFieldValue("picture", event.target.files![0]);
                setTmpPicture(URL.createObjectURL(event.target.files![0]));
              }}
            />
          </div>
          <div className="formFieldGroup formFieldGroup--third">
            <div className="formField">
              <label htmlFor="accomodationName">
                {i18n.t("accomodation_name")}
              </label>
              <Field
                type="text"
                id="name"
                name="name"
                placeholder={i18n.t("accomodation_name")}
              />
              {errors.name && touched.name ? <div>{errors.name}</div> : null}
            </div>
            <div className="formField">
              <label htmlFor="accomodationType">
                {i18n.t("accomodation_type")}
              </label>
              <Field
                as="select"
                id="typeId"
                name="typeId"
                placeholder={i18n.t("accomodation_type")}
              >
                {accomodation.accomodationTypes.map((type) => (
                  <option key={type.id} value={type.id} label={type.label} />
                ))}
              </Field>
              {errors.typeId && touched.typeId ? (
                <div>{errors.typeId}</div>
              ) : null}
            </div>
            <div className="formField">
              <label htmlFor="accomodationGroup">
                {i18n.t("group")}{" "}
                <span className="optionnal">({i18n.t("optional")})</span>
              </label>

              <Field
                as="select"
                id="groupId"
                name="groupId"
                placeholder={i18n.t("group")}
              >
                <option value="" label=" -- " />
                {group.groups.map((group) => {
                  if (group.id !== 0) {
                    return (
                      <option
                        key={group.id}
                        value={group.id}
                        label={group.name}
                      />
                    );
                  }
                  return null;
                })}
              </Field>
              {errors.groupId && touched.groupId ? (
                <div>{errors.groupId}</div>
              ) : null}
            </div>
          </div>

          <div className="formField">
            <label htmlFor="accomodationAddress">{i18n.t("address")}</label>
            <Field
              type="text"
              id="address"
              name="address"
              placeholder={i18n.t("address")}
            />
            {errors.address && touched.address ? (
              <div>{errors.address}</div>
            ) : null}
          </div>

          <div className="formField">
            <label htmlFor="accomodationDescription">
              {i18n.t("description")}
            </label>
            <Field
              as="textarea"
              id="description"
              name="description"
              placeholder={i18n.t("description")}
            />
            {errors.description && touched.description ? (
              <div>{errors.description}</div>
            ) : null}
          </div>

          <div className="formFieldGroup formFieldGroup--third">
            {values.typeId === "2" && (
              <div className="formField">
                <label htmlFor="accomodationFloor">{i18n.t("floor")}</label>
                <Field
                  as="select"
                  id="floor"
                  name="floor"
                  placeholder={i18n.t("floor")}
                >
                  <option value="0" label="Rez-de-chaussée" />
                  <option value="1" label="1" />
                  <option value="2" label="2" />
                  <option value="3" label="3" />
                  <option value="4" label="4" />
                  <option value="5" label="5" />
                </Field>
                {errors.floor && touched.floor ? (
                  <div>{errors.floor}</div>
                ) : null}
              </div>
            )}

            <div className="formField">
              <label htmlFor="accomodationSurface">{i18n.t("surface")}</label>
              <Field
                type="number"
                name="surface"
                id="surface"
                placeholder={i18n.t("surface")}
              />
              {errors.surface && touched.surface ? (
                <div>{errors.surface}</div>
              ) : null}
            </div>
            <div className="formField">
              <label htmlFor="accomodationNbrRooms">
                {i18n.t("number_of_pieces")}
              </label>
              <Field
                as="select"
                id="numberPieces"
                name="numberPieces"
                placeholder={i18n.t("number_of_pieces")}
              >
                <option value="1" label="1" />
                <option value="2" label="2" />
                <option value="3" label="3" />
                <option value="4" label="4" />
                <option value="5" label="5" />
              </Field>
              {errors.numberPieces && touched.numberPieces ? (
                <div>{errors.numberPieces}</div>
              ) : null}
            </div>
          </div>

          <div className="formFieldGroup formFieldGroup--third">
            <div className="">
              <label>{i18n.t("furnished")}</label>
              <div className="formField formField--radio">
                <Field
                  type="radio"
                  id="furnished"
                  name="furnished"
                  value="true"
                />
                <label htmlFor="furnished">Oui</label>

                <Field
                  type="radio"
                  id="notFurnished"
                  name="furnished"
                  value="false"
                />
                <label htmlFor="notFurnished">Non</label>
              </div>
            </div>
            <div className="formField">
              <label htmlFor="accomodationGES">
                {i18n.t("greenhouse_gases")}
              </label>
              <Field
                as="select"
                id="ges"
                name="ges"
                placeholder={i18n.t("greenhouse_gases")}
              >
                {energyClass.map((energy) => (
                  <option key={energy} value={energy} label={energy} />
                ))}
              </Field>
              {errors.ges && touched.ges ? <div>{errors.ges}</div> : null}
            </div>

            <div className="formField">
              <label htmlFor="accomodationEnergy">
                {i18n.t("energy_class")}
              </label>
              <Field
                as="select"
                id="energyClass"
                name="energyClass"
                placeholder={i18n.t("energy_class")}
              >
                {energyClass.map((energy) => (
                  <option key={energy} value={energy} label={energy} />
                ))}
              </Field>
              {errors.energyClass && touched.energyClass ? (
                <div>{errors.energyClass}</div>
              ) : null}
            </div>
          </div>

          <div className="formFieldGroup formFieldGroup--third">
            <div className="formField">
              <label htmlFor="accomodationRent">{i18n.t("rent")} (€)</label>
              <Field
                type="number"
                name="rentPrice"
                id="rentPrice"
                placeholder={i18n.t("rent")}
              />
              {errors.rentPrice && touched.rentPrice ? (
                <div>{errors.rentPrice}</div>
              ) : null}
            </div>

            <div className="formField">
              <label htmlFor="accomodationCharges">
                {i18n.t("expenses")} (€)
              </label>
              <Field
                type="number"
                name="expenses"
                id="expenses"
                placeholder={i18n.t("expenses")}
              />
              {errors.expenses && touched.expenses ? (
                <div>{errors.expenses}</div>
              ) : null}
            </div>

            <div className="formField">
              <label htmlFor="accomodationCaution">
                {i18n.t("deposit")} (€)
              </label>
              <Field
                type="number"
                name="deposit"
                id="deposit"
                placeholder={i18n.t("deposit")}
              />
              {errors.deposit && touched.deposit ? (
                <div>{errors.deposit}</div>
              ) : null}
            </div>
          </div>

          <button type="submit">
            <i className="far fa-save"></i> {i18n.t("save")}
          </button>
        </Form>
      )}
    </Formik>
  );
};
