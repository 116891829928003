import React, { useEffect, useState } from "react";
import i18n from "i18n-js";
import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useCreateAccomodationStore } from "../../../../lib/store/createAccomodation/useCreateGroupStore";
import { useTenantStore } from "../../../../lib/store/tenant/useTenantStore";
import { TenantCreationScheme } from "./SecondStep.scheme";
import { Tenant } from "../../../../lib/models/Tenant";

export const SecondStep = observer(() => {
  const tenants = useTenantStore();
  const createAccomodation = useCreateAccomodationStore();
  const tenantSelected = !!createAccomodation.tenantId;

  const [filter, setFilter] = useState<string>("");
  const [filteredTenant, setFilteredTenant] = useState<Tenant[] | []>([]);

  useEffect(() => {
    setFilteredTenant(
      tenants.tenants
        .filter((tenant: Tenant) => {
          const name = `${tenant.firstName} ${tenant.lastName}`;

          return (
            filter === "" || name.toLowerCase().includes(filter.toLowerCase())
          );
        })
        .map((t) => t)
        .sort((a, b) => a.firstName.localeCompare(b.firstName))
    );
  }, [filter]);

  return (
    <>
      <div className="integrateAccomodations">
        <h2>{i18n.t("already_exist_tenant")}</h2>
        <input
          type="search"
          placeholder="Rechercher"
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>

      <div className="tenantsWrapper">
        {filteredTenant.map((tenant) => {
          return (
            <div
              key={tenant.id!}
              className={`loopTenant${
                createAccomodation.tenantId === tenant.id ? " current" : ""
              }`}
              onClick={() => createAccomodation.manageTenant(tenant.id!)}
            >
              <p className="loopTenantThumb">
                <img src="https://picsum.photos/50/50" alt="" />
              </p>
              <p className="loopTenantContent">
                <span>
                  {tenant.firstName} {tenant.lastName}
                </span>
              </p>
            </div>
          );
        })}
      </div>

      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          entryDate: "",
          entryDateUpdate: "",
        }}
        onSubmit={(values: any) => {
          if (tenantSelected) {
            createAccomodation.updateTenant({
              entryDate: values.entryDateUpdate,
            });
          } else {
            createAccomodation.createTenant(
              values.firstName !== "" ? values : null
            );
          }
        }}
        validationSchema={TenantCreationScheme}
      >
        {({ dirty, errors, touched }) => (
          <Form>
            <div className="formFieldGroup formFieldGroup--third">
              <div className="formField">
                <label htmlFor="tenantEntryDate">{i18n.t("entry_date")}</label>
                <Field
                  type="date"
                  id="entryDateUpdate"
                  name="entryDateUpdate"
                  placeholder={i18n.t("entry_date")}
                  required={tenantSelected}
                />
              </div>
            </div>

            <p className="formSeparator">
              <span>{i18n.t("or")}</span>
            </p>

            <h2>{i18n.t("create_new_tenant")}</h2>
            <div className="formFieldGroup formFieldGroup--third">
              <div className="formField">
                <label htmlFor="tenantLastName">{i18n.t("firstName")}</label>
                <Field
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder={i18n.t("firstName")}
                  required={dirty && !tenantSelected}
                />
              </div>
              <div className="formField">
                <label htmlFor="tenantLastName">{i18n.t("lastName")}</label>
                <Field
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder={i18n.t("lastName")}
                  required={dirty && !tenantSelected}
                />
              </div>
            </div>

            <div className="formFieldGroup formFieldGroup--third">
              <div className="formField">
                <label htmlFor="tenantEmail">{i18n.t("email")}</label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder={i18n.t("email")}
                  required={dirty && !tenantSelected}
                />
                {dirty && errors.email && touched.email ? (
                  <div>{errors.email}</div>
                ) : null}
              </div>
              <div className="formField">
                <label htmlFor="tenantPhone">{i18n.t("phone")}</label>
                <Field
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder={i18n.t("phone")}
                  required={dirty && !tenantSelected}
                />
              </div>
            </div>

            <div className="formFieldGroup formFieldGroup--third">
              <div className="formField">
                <label htmlFor="tenantEntryDate">{i18n.t("entry_date")}</label>
                <Field
                  type="date"
                  id="entryDate"
                  name="entryDate"
                  placeholder={i18n.t("entry_date")}
                  required={dirty && !tenantSelected}
                />
              </div>
            </div>

            <div className="formField formField--buttons">
              <button type="submit">{i18n.t("skip")}</button>
              <button type="submit">
                <i className="far fa-save"></i> {i18n.t("save")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
});
