import React from "react";
import i18n from "i18n-js";
import ornement from "../assets/images/ornement.svg";
import { GroupCreation } from "../components/Group/GroupCreation/GroupCreation";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useCreateGroupStore } from "../lib/store/createGroup/useCreateGroupStore";

export const GroupCreationScreen = observer(() => {
  const navigate = useNavigate();
  const createGroup = useCreateGroupStore();

  return (
    <div className="mainContentBody">
      <h1>
        {i18n.t("creation")}
        <span className="step">
          <img src={ornement} alt="" />
          {createGroup.currentStep}
        </span>
        /2
      </h1>

      {createGroup.currentStep === 1 ? (
        <>
          <div className="formField formField--radio">
            <input type="radio" id="groupe" name="creation" defaultChecked />
            <label htmlFor="groupe">{i18n.t("group")}</label>

            <input
              type="radio"
              id="logement"
              name="creation"
              onClick={() => navigate("../createAccomodation")}
            />
            <label htmlFor="logement">{i18n.t("accomodation")}</label>
          </div>
          <GroupCreation.FirstStep />
        </>
      ) : (
        <GroupCreation.SecondStep  />
      )}
    </div>
  );
});
