import React from "react";
import { Outlet } from "react-router-dom";
import { Navigation } from "../components/Navigation/Navigation";

export const HomeScreen = () => {
  return (
    <section className="appWrapper">
      <Navigation />

      <Outlet />
    </section>
  );
};
