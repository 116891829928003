import { action, makeAutoObservable, observable, runInAction } from "mobx";
import {
  fetchAccomodationById,
  fetchAccomodations,
  fetchAccomodationsType,
} from "../../api/accomodations";
import { Accomodation } from "../../models/Accomodation";
import { Type } from "../../models/Type";
import AuthStore from "../auth/AuthStore";
import LoaderStore from "../loader/LoaderStore";
import UserStore from "../user/UserStore";

export default class AccomodationStore {
  public auth: AuthStore;

  public user: UserStore;

  public loader: LoaderStore;

  @observable
  current: Accomodation | null = null;

  @observable
  accomodationsWithoutGroup: Accomodation[] | [] = [];

  @observable
  accomodationTypes: Type[] | [] = [];

  constructor(rootStore: any) {
    this.loader = rootStore.loaderStore;
    this.auth = rootStore.authStore;
    this.user = rootStore.userStore;

    makeAutoObservable(this);
  }

  @action
  async fetchAccomodationsWithoutGroup() {
    try {
      const accomodations: any = await fetchAccomodations(
        this.auth.userToken,
        this.user.currentUser?.ulid
      );

      runInAction(() => {
        this.accomodationsWithoutGroup = accomodations["hydra:member"].filter(
          (acc: Accomodation) => !acc.linkedGroup
        );
      });
    } catch (error) {
      console.log("fetchAccomodation", error);
    }
  }

  @action
  async fetchAccomodationById(id: number) {
    this.loader.startLoading();
    try {
      const accomodation = await fetchAccomodationById(this.auth.userToken, id);

      runInAction(() => {
        this.current = accomodation;
      });
      this.loader.stopLoading();
    } catch (error) {
      this.loader.stopLoading();
      console.log("fetchAccomodationById", error);
    }
  }

  @action
  async fetchAccomodationTypes() {
    try {
      const types: any = await fetchAccomodationsType(this.auth.userToken);

      runInAction(() => {
        this.accomodationTypes = types["hydra:member"];
      });
    } catch (error) {
      console.log("fetchAccomodationById", error);
    }
  }

  @action
  async refreshCurrentAcc() {
    try {
      const accomodation = await fetchAccomodationById(
        this.auth.userToken,
        this.current?.id
      );

      runInAction(() => {
        this.current = accomodation;
      });
    } catch (error) {
      console.log("refreshCurrentAcc", error);
    }
  }
}
