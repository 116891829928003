import React, { FC } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import fileDownload from "js-file-download";
import { lowerCase } from "lodash";
import { patchDoc } from "../../../../lib/api/document";
import { Document } from "../../../../lib/models/Document";
import { useAccomodationStore } from "../../../../lib/store/accomodation/useAccomodationStore";
import { useAuthStore } from "../../../../lib/store/auth/useAuthStore";
import { AccomodationDocumentItemComponent } from "./AccomodationDocumentItem.type";
import { useModalStore } from "../../../../lib/store/modal/ModalStore";
import { mimeTypeMap } from "../../../../lib/constants/mimeType";
import { getKeyFromMapArray } from "../../../../lib/utils/mapTools";
import { useLoaderStore } from "../../../../lib/store/loader/useLoaderStore";

export const AccomodationDocumentItem: FC<
  AccomodationDocumentItemComponent
> = ({ document }) => {
  const auth = useAuthStore();
  const accomodation = useAccomodationStore();
  const modal = useModalStore();
  const loader = useLoaderStore();
  const { accomodationId } = useParams();

  const handleMakeModal = () => {
    modal.makeNewModal(
      "Voulez-vous vraiment effectué cette action ?",
      `Souhaitez-vous changer le status d'archive du document ${document.name}.${document.extention} ?`,
      handleArchive
    );
  };

  const handleArchive = async () => {
    loader.startLoading();
    const updateDow: Document = { ...document, archive: !document.archive };
    await patchDoc(auth.userToken, updateDow);

    await accomodation.fetchAccomodationById(Number(accomodationId));
    loader.stopLoading();
  };

  const handleDL = () => {
    axios({
      method: "GET",
      url: document.url,
      responseType: "blob",
    })
      .then((response) => {
        fileDownload(response.data, `${document.name}.${document.extention}`);
      })
      .catch((error) => {
        console.log("error :>> ", error);
      });
  };

  return (
    <div className="documentsListItem">
      <p
        className={`documentsListItemType documentsListItemType--${getKeyFromMapArray(
          mimeTypeMap,
          lowerCase(document.extention)
        )}`}
      >
        <i className="far fa-file-alt"></i>
      </p>
      <p className="documentsListItemContent">
        <strong>
          {document.name}.{document.extention}
        </strong>
        <span>{document.size}</span>
      </p>
      <ul>
        <li>
          <i className="fas fa-download" onClick={handleDL}></i>
        </li>
        <li>
          <i
            className={`${document.archive ? "fas" : "far"} fa-file-zipper`}
            onClick={handleMakeModal}
          ></i>
        </li>
      </ul>
    </div>
  );
};
