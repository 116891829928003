import React, { useState } from "react";
import i18n from "i18n-js";
import { Field, Form, Formik } from "formik";
import { FirstStepGroupDTO } from "./FirstStep.type";
import { GroupCreationScheme } from "./FirstStep.scheme";
import { useCreateGroupStore } from "../../../../lib/store/createGroup/useCreateGroupStore";
import placeholder from "../../../../assets/images/placeholder.png";

export const FirstStep = () => {
  const createGroup = useCreateGroupStore();
  const [tmpPicture, setTmpPicture] = useState<string | null>(null);

  return (
    <Formik
      initialValues={{
        name: "",
        city: "",
        description: "",
      }}
      onSubmit={(values: FirstStepGroupDTO) => {
        createGroup.saveForm(values);
      }}
      validationSchema={GroupCreationScheme}
    >
      {({ errors, touched, setFieldValue }) => (
        <Form>
          <div className="your-file accomodationThumbnail">
            <div className="your-file-preview">
              <img src={tmpPicture ? tmpPicture : placeholder} alt="" />
            </div>
            <label className="button">Ajouter Image</label>
            <input
              id="picture"
              name="picture"
              type="file"
              accept="image/*"
              onChange={(event) => {
                setFieldValue("picture", event.target.files![0]);
                setTmpPicture(URL.createObjectURL(event.target.files![0]));
              }}
            />
          </div>
          <div className="formFieldGroup formFieldGroup--third">
            <div className="formField">
              <label htmlFor="groupeName">{i18n.t("group_name")}</label>
              <Field
                type="text"
                id="name"
                name="name"
                placeholder={i18n.t("group_name")}
              />
              {errors.name && touched.name ? <div>{errors.name}</div> : null}
            </div>
            <div className="formField">
              <label htmlFor="groupeCity">{i18n.t("city")}</label>
              <Field
                type="text"
                id="city"
                name="city"
                placeholder={i18n.t("city")}
              />
              {errors.city && touched.city ? <div>{errors.city}</div> : null}
            </div>
          </div>

          <div className="formField">
            <label htmlFor="groupeDescription">{i18n.t("description")}</label>
            <Field
              as="textarea"
              id="description"
              name="description"
              placeholder={i18n.t("description")}
              required
            />
            {errors.description && touched.description ? (
              <div>{errors.description}</div>
            ) : null}
          </div>

          <button type="submit">
            <i className="far fa-save"></i> {i18n.t("save")}
          </button>
        </Form>
      )}
    </Formik>
  );
};
