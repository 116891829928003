import { action, observable, runInAction, makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { login } from "../../api/auth";
import LoaderStore from "../loader/LoaderStore";
import UserStore from "../user/UserStore";

export default class AuthStore {
  public loader: LoaderStore;

  public userStore: UserStore;

  @observable
  isLogged: boolean = false;

  @observable
  userToken: string | undefined = undefined;

  constructor(private readonly rootStore: any) {
    this.loader = rootStore.loaderStore;
    this.userStore = rootStore.userStore;

    makeAutoObservable(this);

    makePersistable(this, {
      name: "AuthStore",
      properties: ["userToken", "isLogged"],
      storage: window.localStorage,
    });
  }

  @action
  async login(email: string, password: string) {
    this.loader.startLoading();
    try {
      const { token } = await login(email, password);

      runInAction(() => {
        this.userToken = token;
        this.isLogged = true;
      });

      if (this.userToken) {
        await this.userStore?.getUser(this.userToken);
      }

      this.loader.stopLoading();
      return true;
    } catch (error) {
      console.log("error", error);
      this.loader.stopLoading();
      return false;
    }
  }

  @action
  logout() {
    runInAction(() => {
      this.userToken = undefined;
      this.isLogged = false;
    });
    return true;
  }
}
