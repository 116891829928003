import React, { useState } from "react";
import i18n from "i18n-js";
import { lowerCase } from "lodash";
import { useCreateAccomodationStore } from "../../../../lib/store/createAccomodation/useCreateGroupStore";
import {
  fileConvertSize,
  splitNameExtention,
} from "../../../../lib/utils/fileTools";
import { useNavigate } from "react-router-dom";
import { mimeTypeMap } from "../../../../lib/constants/mimeType";
import { getKeyFromMapArray } from "../../../../lib/utils/mapTools";

export const ThirdStep = () => {
  const navigate = useNavigate();
  const createAccomodation = useCreateAccomodationStore();
  const [files, setFiles] = useState<any>([]);

  const handleAdd = (e: any) => {
    if (e.target.files.length > 0) {
      let fls: any = [];
      Array.from(e.target.files).forEach((file: any) => {
        if (!files.find((f: any) => f.file.name === file.name)) {
          fls.push({ file: file, archived: false });
        }
      });
      if (fls.length > 0) {
        setFiles([...files, ...fls]);
      }
    }
  };

  const handleRemove = (file: any) => {
    setFiles(files.filter((f: any) => f.file !== file.file));
  };

  const handleCreate = async () => {
    const newAccId = await createAccomodation.addDocuments(files);

    if (newAccId) navigate(`../${newAccId}`);
  };

  const handleArchive = (file: any) => {
    setFiles(
      files.map((f: any) => {
        if (f.file === file.file) {
          file.archived = !file.archived;
        }
        return f;
      })
    );
  };

  return (
    <>
      <div className="integrateAccomodations">
        <h2>{i18n.t("accomodation_documents")}</h2>
        <div className="your-file">
          <label className="button">
            <i className="fas fa-plus"></i> {i18n.t("add")}
          </label>
          <input
            type="file"
            id="document_uploads"
            name="document_uploads"
            multiple
            onChange={handleAdd}
          />
        </div>
      </div>

      <div className="your-file">
        <div className="documentsList panel">
          {files.length > 0 ? (
            files.map((file: any) => (
              <div key={Math.random()} className="documentsListItem">
                <p
                  className={`documentsListItemType documentsListItemType--${getKeyFromMapArray(
                    mimeTypeMap,
                    lowerCase(splitNameExtention(file.file).extention)
                  )}`}
                >
                  <i className="far fa-file-alt"></i>
                </p>
                <p className="documentsListItemContent">
                  <strong>{file.file.name}</strong>
                  <span>{fileConvertSize(file.file.size)}</span>
                </p>
                <ul>
                  <li>
                    <i
                      className={`${
                        file.archived ? "fas" : "far"
                      } fa-file-zipper`}
                      onClick={() => handleArchive(file)}
                    ></i>
                  </li>
                  <li>
                    <i
                      className="far fa-trash-alt"
                      onClick={() => handleRemove(file)}
                    ></i>
                  </li>
                </ul>
              </div>
            ))
          ) : (
            <p>{i18n.t("no_documents")}</p>
          )}
        </div>
        <input
          type="file"
          multiple
          id="image_uploads"
          name="image_uploads"
          onChange={handleAdd}
        ></input>
      </div>

      <div className="formField formField--buttons">
        <button type="submit" onClick={handleCreate}>
          {i18n.t("skip")}
        </button>
        <button type="submit" onClick={handleCreate}>
          <i className="far fa-save"></i> {i18n.t("save")}
        </button>
      </div>
    </>
  );
};
